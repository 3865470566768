*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

html, body, #root {
  width: 100%;
  height: 100vh;
  overflow: auto;
}

@media screen and (min-width: 768px){
  #root {
    background-image: url('../public/img/pattern.jpeg');
    background-repeat: repeat;
    background-size: 256px;
  }
}

.backButton {
  position: absolute;
  top: 1rem;
  left: 2rem;
}

.hidden {
  display: none !important;
}